import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

import { Manager } from "../../../Interfaces/User";

import DefaultImg from "../../../images/profile-pic-icon.png";

import PhoneIcon from "../../../images/profile_phone_icon@2x.png";
import MailIcon from "../../../images/profile_mail_icon@2x.png";

import * as DomesticHelpCardStyle from "./DomesticHelpCard.module.scss";

interface Props {
  isHelper?: boolean;
  manager: Manager;
  onClickRemove: () => void;
}

const Index = ({ manager: { manager }, onClickRemove, isHelper }: Props) => {
  const getRole = () => {
    const role = manager.roles?.filter(
      (role) =>
        role !== "ROLE_DOMESTIC" &&
        role !== "ROLE_PARTNER" &&
        role !== "ROLE_ADMIN" &&
        role !== "ROLE_USER"
    );

    if (role?.length) return role[0]?.split("_")[2];
    return "N/A";
  };

  return (
    <div className={DomesticHelpCardStyle.card}>
      <div className={DomesticHelpCardStyle.card__content}>
        <img
          src={manager.userMedias?.collection[0]?.mediaUrl?.url || DefaultImg}
          width={54}
          height={54}
          alt="manager"
          {...{
            style: {
              borderRadius: "100px",
              objectFit: "cover",
              objectPosition: "top",
            },
          }}
        />
        <div className={DomesticHelpCardStyle.card__content__section}>
          <h6>{getRole()}</h6>
          <span>{manager.name}</span>
        </div>
        <div className={DomesticHelpCardStyle.card__content__section}>
          <div className={DomesticHelpCardStyle.card__content__section__detail}>
            <img src={PhoneIcon} alt="call" width={32} height={32} />
            <span>{manager.contactNumber}</span>
          </div>
          <div className={DomesticHelpCardStyle.card__content__section__detail}>
            <img src={MailIcon} alt="call" width={32} height={32} />
            <span>{manager.email || "N/A"}</span>
          </div>
        </div>
      </div>
      <div className={DomesticHelpCardStyle.card__footer}>
        <button
          onClick={() => {
            Swal.fire({
              type: "info",
              title: "Warning",
              text: isHelper
                ? "You Want To Remove This Helper?"
                : "You Want to Remove This Manager",
              showCancelButton: true,
            }).then(({ value }) => {
              if (value) {
                onClickRemove();
              }
            });
          }}
          className={DomesticHelpCardStyle.card__footer__btn}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default Index;
