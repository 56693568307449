import {
  faCommentAlt,
  faUserCircle,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Complaint } from "../../../Interfaces/Complaint";

import DeleteIcon from "../../../images/delete_dark_icon@2x.png";

import * as HelpDeskCardStyle from "./HelpDeskCard.module.scss";
import { navigate } from "gatsby";

interface Props {
  complaint: Complaint;
  onRemove: () => void;
  canRemove: boolean;
}

const Index = ({ complaint, onRemove, canRemove }: Props) => {
  return (
    <button
      className={HelpDeskCardStyle.btn}
      onClick={() =>
        navigate(
          `/complaintdetails/${
            complaint.residence.parkingArea.id.split("/")[2]
          }/${complaint.id.split("/")[2]}`
        )
      }
    >
      <div className={HelpDeskCardStyle.card}>
        {canRemove && (
          <button
            className={HelpDeskCardStyle.removebtn}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Swal.fire({
                type: "info",
                title: "Warning",
                text: "Are You Sure?",
                showCancelButton: true,
              }).then(({ value }) => {
                if (value) {
                  onRemove();
                }
              });
            }}
          >
            <img src={DeleteIcon} alt="remove" width={28} height={28} />
          </button>
        )}

        <div className={HelpDeskCardStyle.card__content}>
          <h6>{complaint.category.code}</h6>
          <div className={HelpDeskCardStyle.card__content__item}>
            <FontAwesomeIcon icon={faCommentAlt} />
            <span>{complaint.description}</span>
          </div>
          <div className={HelpDeskCardStyle.card__content__item}>
            <FontAwesomeIcon icon={faUserCircle} />
            <span>Raised By {complaint?.user?.name}</span>
          </div>
          <div className={HelpDeskCardStyle.card__content__item}>
            <FontAwesomeIcon icon={faUserCog} />
            <span>
              {complaint?.manager?.name
                ? `Assigned To ${complaint.manager.name}`
                : "Not Assigned"}
            </span>
          </div>
        </div>

        <div className={HelpDeskCardStyle.card__footer}>
          <span
            className={`${HelpDeskCardStyle.badge} ${HelpDeskCardStyle.badge__status}`}
          >
            {complaint.status.code}
          </span>
          {complaint.isUrgent && (
            <span
              className={`${HelpDeskCardStyle.badge} ${HelpDeskCardStyle.badge__urgent}`}
            >
              Urgent
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export default Index;
