import { gql } from "@apollo/client";

export const CreateComplaintQuery = gql`
  mutation CreateComplaint(
    $residence: String!
    $isUrgent: Boolean!
    $category: String!
    $description: String!
  ) {
    createResidenceComplaint(
      input: {
        residence: $residence
        isUrgent: $isUrgent
        category: $category
        description: $description
      }
    ) {
      residenceComplaint {
        id
      }
    }
  }
`;

export const UpdateResidentComplaint = gql`
  mutation UpdateComplaint(
    $id: ID!
    $status: String
    $manager: String
    $comment: String
  ) {
    updateResidenceComplaint(
      input: {
        id: $id
        status: $status
        manager: $manager
        lastComment: $comment
      }
    ) {
      residenceComplaint {
        id
        isUrgent
        description
        createdAt
        status {
          code
        }
        category {
          code
        }
        residenceComplaintLogs(
          order: [{ createdAt: "DESC" }]
          itemsPerPage: 5
        ) {
          collection {
            id
            status {
              id
              code
            }
            admin {
              id
              name
              userMedias {
                collection {
                  id
                  mediaUrl
                }
              }
            }
            createdAt
            comment
          }
        }
        manager {
          id
          name
          email
        }
        user {
          id
          name
        }
      }
    }
  }
`;

export const GetComplaintsQuery = gql`
  query Complaints(
    $parkingArea: String
    $residence: String
    $order: [ResidenceComplaintFilter_order]
    $page: Int
    $status_list: [String]
    $manager: String
  ) {
    residenceComplaints(
      residence_parkingArea: $parkingArea
      residence: $residence
      order: $order
      page: $page
      status_code_list: $status_list
      manager: $manager
    ) {
      paginationInfo {
        lastPage
      }
      collection {
        id
        isUrgent
        description
        createdAt
        status {
          code
        }
        residence {
          id
          parkingArea {
            id
          }
        }
        category {
          code
        }
        manager {
          name
          email
        }
        user {
          id
          name
        }
      }
    }
  }
`;

export const GetComplaintCategory = gql`
  query {
    complaintCategories {
      collection {
        id
        code
        name
      }
    }
  }
`;

export const GetResidantComplaint = gql`
  query Complaint($id: ID!, $order: [ResidenceComplaintLogFilter_order]) {
    residenceComplaint(id: $id) {
      id
      isUrgent
      description
      createdAt
      updatedAt
      lastComment
      status {
        id
        code
      }
      category {
        code
      }
      manager {
        id
        name
        email
        contactNumber
      }
      user {
        id
        name
        contactNumber
        email
      }
      residenceComplaintLogs(order: $order, itemsPerPage: 100) {
        collection {
          id
          status {
            id
            code
          }
          admin {
            id
            name
            userMedias {
              collection {
                id
                mediaUrl
              }
            }
          }
          createdAt
          comment
        }
      }
      residence {
        flatName
        towerName
        parkingArea {
          id
          name
          parkingAreaManagers {
            collection {
              id
              role
              manager {
                name
                id
                roles
              }
            }
          }
        }
      }
    }
  }
`;

export const GetComplaintManagers = gql`
  query ParkingManagers(
    $parkingArea: String
    $role: String
    $status: String
    $order: [ParkingAreaManagerFilter_order]
    $status_list: [String]
    $itemsPerPage: Int
  ) {
    parkingAreaManagers(
      parkingArea: $parkingArea
      role: $role
      status_code: $status
      order: $order
      status_code_list: $status_list
      itemsPerPage: $itemsPerPage
    ) {
      paginationInfo {
        lastPage
      }
      collection {
        id
        role
        manager {
          id
          email
          roles
          name
          contactNumber
          gender {
            code
          }
          userMedias {
            collection {
              id
              mediaUrl
            }
          }
        }
        admin {
          name
        }
      }
    }
  }
`;

export const GetComplaintStatuesQuery = gql`
  query residantComplaintStatus {
    residenceComplaintStatuses {
      collection {
        id
        code
      }
    }
  }
`;
