import React, { useEffect } from "react";

import { Downgraded, useState as useHookState } from "@hookstate/core";

import Layout from "src/Components/Layout";
import Visitors from "src/Components/Visitor";
import DomesticHelp from "src/Components/DomesticHelp";
import HelpDesk from "src/Components/HelpDesk";
import Managers from "src/Components/Managers";
import Residence from "src/Components/Residence";
import NoticeBoard from "src/Components/NoticeBoard";
import Gates from "src/Components/Gates";
import AreaRoles from "../../../../../../States/AreaRoles";
import { RoleLogin } from "../../../../../../utils/Auth";
import AppState from "../../../../../../States/AppState";
import { navigate } from "gatsby";

const SocietyPage = ({ section, areaid, areaname, managerid, managerType }) => {
  const areaRoles = useHookState(AreaRoles).get();
  useEffect(() => {
    const user = AppState.attach(Downgraded).user.get();
    const currentArea = areaRoles.areas.filter(
      ({ areaId: id }) => id.split("/")[2] === areaid
    )[0];
    if (currentArea) {
      if (currentArea.role.length === 1) {
        return;
      }
      if (
        section === "visitor" &&
        currentArea.role.includes("ROLE_GATEKEEPER")
      ) {
        return;
      }
      if (section === "gates" && currentArea.role.includes("ROLE_GATEKEEPER")) {
        return;
      }
      if (
        section === "helpdesk" &&
        (currentArea.role.includes("ROLE_HELPER") ||
          currentArea.role.includes("ROLE_MANAGER_HELPDESK"))
      ) {
        return;
      }

      if (user) navigate(RoleLogin(user));
    }
  }, [areaRoles]);
  return (
    <Layout isPrivate showHeader>
      <>
        {section === "visitor" && (
          <Visitors
            areaId={areaid}
            areaName={areaname}
            gateId={managerid !== "_" ? managerid : undefined}
          />
        )}

        {section === "gates" && (
          <Gates
            areaId={areaid}
            areaName={areaname}
            managerId={managerid !== "_" ? managerid : undefined}
          />
        )}

        {section === "domestichelp" && (
          <DomesticHelp
            areaId={areaid}
            areaName={areaname}
            managerId={managerid !== "_" ? managerid : undefined}
          />
        )}
        {section === "helpdesk" && (
          <HelpDesk
            areaId={areaid}
            areaName={areaname}
            managerId={managerid !== "_" ? managerid : undefined}
          />
        )}
        {section === "managers" && (
          <Managers
            areaId={areaid}
            areaName={areaname}
            managerId={managerid !== "_" ? managerid : undefined}
          />
        )}
        {section === "residents" && (
          <Residence
            areaId={areaid}
            areaName={areaname}
            managerId={managerid !== "_" ? managerid : undefined}
          />
        )}
        {section === "noticeboard" && (
          <NoticeBoard
            areaId={areaid}
            areaName={areaname}
            managerId={managerid !== "_" ? managerid : undefined}
          />
        )}
      </>
    </Layout>
  );
};

export default SocietyPage;
