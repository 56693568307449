import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import ResidenceCard from "./ResidenceCard";
import Loader from "../Loader";

import { GetResidancesQuery, Tower } from "../../Interfaces/ParkingArea";

import { GetResidencesQuery } from "../../Query/ParkingArea";

import AddImage from "../../images/plus_icon2@2x.png";

import * as ResidenceStyle from "./Residence.module.scss";
import Logger from "../../utils/Logger";
import Server from "../../utils/Server";
import { AxiosError } from "axios";

interface Props {
  areaId: string;
  areaName: string;
  managerId?: string;
}

const Index = ({ areaId, areaName }: Props) => {
  const [residences, setResidences] = useState<
    { towerName: string; data: Tower[] }[]
  >([]);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const {
    data: residancesData,
    loading: residancesLoading,
    error: residancesError,
    fetchMore,
    refetch,
  } = useQuery<GetResidancesQuery>(GetResidencesQuery, {
    variables: {
      parkingArea: `/parking_areas/${areaId}`,
      userStatuses: ["ACTIVE", "INACTIVE"],
    },
  });

  useEffect(() => {
    if (residancesData?.residences.collection) {
      const temp = residancesData.residences?.collection
        .reduce((a, p) => {
          return !a.includes(p.towerName) ? [...a, p.towerName] : a;
          // eslint-disable-next-line @typescript-eslint/no-array-constructor
        }, Array())
        .map((t) => {
          const d = residancesData.residences.collection.filter(
            ({ towerName }) => towerName === t
          );
          return {
            towerName: `${t}`,
            data: d,
          };
        });

      setResidences(temp);
    }
  }, [residancesData]);

  useEffect(() => {
    const err = residancesError;
    if (err) Logger.showError(err);
  }, [residancesError]);

  useEffect(() => {
    refetch();
  }, []);

  const removeResidanceMember = (userResidanceId: string) => {
    return new Promise((resolve, reject) => {
      const showtUserResidanceId = userResidanceId.split("/")[2];
      Server.del(`/user/residence/${showtUserResidanceId}/remove`)
        .then((res) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  };

  return (
    <>
      <div className={ResidenceStyle.container}>
        <div className={ResidenceStyle.header}>
          <span>{areaName} (Residences)</span>
          <button
            onClick={() => {
              navigate(`/addresidence/${areaId}`);
            }}
            className="addbtn"
          >
            <span>Add Residence</span>
            <img src={AddImage} alt="" />
          </button>
        </div>
        {!residences.length && <div className="avgText">No Residences</div>}
        <div className={ResidenceStyle.content}>
          {residences
            .sort((a, b) =>
              a?.towerName.localeCompare(b.towerName, "es", {
                sensitivity: "base",
              })
            )
            .map(({ data, towerName }, i) => (
              <div
                key={`${towerName}/${i}`}
                className={ResidenceStyle.content__items}
              >
                <h6>Tower: {towerName}</h6>
                <div className={ResidenceStyle.content__items__item}>
                  {data?.map((residence) => (
                    <ResidenceCard
                      residence={residence}
                      onView={() => {
                        navigate(
                          `/residencedetail/${residence.id.split("/")[2]}`
                        );
                      }}
                      onDelete={() => {}}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
        {residancesData?.residences &&
          residancesData.residences.paginationInfo.lastPage > page && (
            <div className="loadmore">
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                  })
                    .then((res) => {
                      const newDate = res?.data?.residences?.collection;
                      setPage(page + 1);
                      const temp = Array.from(
                        new Set([
                          ...residences.map(({ towerName }) => towerName),
                          ...newDate.map(({ towerName }) => towerName),
                        ])
                      );
                      const t = temp.map((towerName) => {
                        return {
                          towerName: towerName,
                          data: [
                            ...residences.reduce((a, resi) => {
                              return resi.towerName === towerName
                                ? resi.data
                                : a;
                            }, Array()),
                            ...newDate.reduce((a, resi) => {
                              return resi.towerName === towerName
                                ? [...a, resi]
                                : a;
                            }, Array()),
                          ],
                        };
                      });
                      setResidences(t);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>
      <Loader isLoading={residancesLoading || isLoading} />
    </>
  );
};

export default Index;
