import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import Loader from "../Loader";
import VisitorCard from "./VisitorCard";
import Toggle from "../ToggleButton";

import AddImage from "../../images/plus_icon2@2x.png";
import {
  GetVisitorsQuery,
  UpdateCheckoutime,
  UpdateVisitorStatus,
} from "../../Query/ParkingArea";

import Logger from "../../utils/Logger";

import { Visitor, VisitorLog } from "../../Interfaces/User";

import * as VisitorStyle from "./Visitor.module.scss";

import { Pagination } from "../../Interfaces/Pagination";
import useDebouncedValue from "../../Hooks/useDebouncedValue";

interface Props {
  areaId: string;
  areaName: string;
  gateId?: string;
}

interface GetVisitor {
  residenceVistorLogs: {
    collection: VisitorLog[];
    paginationInfo: Pagination;
  };
}

interface Filter {
  gate?: string;
  visitorName?: string;
  visitorContactNumber?: string;
}

const Index = ({ areaId, areaName, gateId }: Props) => {
  const [searchStr, setSearchStr] = useState<string>();
  const debouncedSearch = useDebouncedValue(searchStr, 500);

  let updateVisitorLoop: NodeJS.Timer | undefined = undefined;

  const [filter, setFilter] = useState<Filter>({
    gate: `/gates/${gateId}`,
  });

  const {
    data: myVisitors,
    loading: loadingMyVisitors,
    error: myVisitorError,
    refetch,
  } = useQuery<GetVisitor>(GetVisitorsQuery, {
    variables: {
      ...filter,
      parkingArea: `/parking_areas/${areaId}`,
      order: [{ createdAt: "DESC" }],
      entryType: "IN",
      itemsPerPage: 50,
    },
    fetchPolicy: "network-only",
  });

  const [
    updateCheckoutime,
    { loading: updadingCheckoutLoading, error: updatingCheckoutError },
  ] = useMutation<{
    createResidenceVistorLog: { residenceVistorLog: VisitorLog };
  }>(UpdateCheckoutime);

  const [
    updateVisitorStatus,
    { error: updateVisitorError, loading: updateVisitorLoading },
  ] =
    useMutation<{ updateResidenceVistor: { residenceVistor: Visitor } }>(
      UpdateVisitorStatus
    );

  useEffect(() => {
    const err = myVisitorError || updatingCheckoutError || updateVisitorError;
    if (err) Logger.showError(err);
  }, [myVisitorError, updatingCheckoutError, updateVisitorError]);

  useEffect(() => {
    refetch();
    updateVisitorLoop = setInterval(() => {
      refetch();
    }, 2000);
    return () => {
      if (updateVisitorLoop) clearInterval(updateVisitorLoop);
    };
  }, []);

  useEffect(() => {
    if (searchStr) {
      if (isNaN(parseInt(searchStr))) {
        setFilter({
          ...filter,
          visitorName: searchStr,
          visitorContactNumber: undefined,
        });
      } else {
        setFilter({
          ...filter,
          visitorContactNumber: searchStr,
          visitorName: undefined,
        });
      }
    } else {
      setFilter({
        ...filter,
        visitorContactNumber: undefined,
        visitorName: undefined,
      });
    }
  }, [debouncedSearch]);

  return (
    <>
      <div className={VisitorStyle.container}>
        <div className={VisitorStyle.header}>
          <span>{areaName}(Visitors)</span>
          <div className={VisitorStyle.header__item}>
            <div className={VisitorStyle.gate}>
              <span>This Gate only</span>
              <Toggle
                selected={!!filter?.gate}
                toggleSelected={() => {
                  setFilter({
                    ...filter,
                    gate: filter?.gate ? undefined : `/gates/${gateId}`,
                  });
                }}
                color="Tertiary"
              />
            </div>
            <div className={VisitorStyle.search}>
              <input
                onChange={(e) => setSearchStr(e.target.value)}
                type="text"
                placeholder="Type Name Or Number To Search"
              />
            </div>
          </div>
          <div className={VisitorStyle.addbtn}>
            <button
              onClick={() => {
                navigate(`/addvisitor/${areaId}/${gateId ? gateId : "_"}`);
              }}
              className={`${VisitorStyle.add} addbtn`}
            >
              <span className="text-nowrap">Add Visitor</span>
              <img src={AddImage} alt="" />
            </button>
          </div>
        </div>
        <div className={VisitorStyle.content}>
          {!myVisitors?.residenceVistorLogs.collection.length && (
            <div className="avgText">No Visitors Found</div>
          )}
          {myVisitors?.residenceVistorLogs?.collection.map((visitorLog) => (
            <VisitorCard
              key={visitorLog.id}
              visitorLog={visitorLog}
              onApprove={() => {
                updateVisitorStatus({
                  variables: {
                    id: visitorLog.residenceVisitor.id,
                    status: "residence_vistor_statuses/2",
                  },
                })
                  .then(() => {
                    refetch();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              onCheckout={(gate) => {
                if (!gateId && !gate)
                  return Logger.showError(
                    new Error("You Are Not assign To any Gates")
                  );
                updateCheckoutime({
                  variables: {
                    visitor: visitorLog.residenceVisitor.id,
                    gate: gate ? gate : `gates/${gateId}`,
                  },
                })
                  .then(() => {
                    refetch();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          ))}
        </div>
      </div>

      <Loader
        isLoading={
          loadingMyVisitors || updadingCheckoutLoading || updateVisitorLoading
        }
      />
    </>
  );
};

export default Index;
