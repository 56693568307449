import { useState, useEffect } from "react"

const useDebouncedValue = (value: unknown, wait: number): unknown => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), wait)
    return () => clearTimeout(id)
  }, [value])

  return debouncedValue
}

export default useDebouncedValue
