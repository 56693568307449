import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import * as AddNoticeStyle from "./AddNotice.module.scss";

interface Inputs {
  content: string;
  subject: string;
}

interface Props {
  onCreate: (data: Inputs) => void;
}

const Index = ({ onCreate }: Props) => {
  const { register, handleSubmit } = useForm();

  const onHandleSubmit: SubmitHandler<Inputs> = (data) => {
    onCreate(data);
  };
  return (
    <form
      onSubmit={handleSubmit(onHandleSubmit)}
      className={AddNoticeStyle.form}
    >
      <div className={AddNoticeStyle.form__group}>
        <label htmlFor="subject" className={AddNoticeStyle.form__group__label}>
          Subject
        </label>
        <input
          id="subject"
          type="text"
          placeholder="Enter Notice Subject"
          className={AddNoticeStyle.form__group__input}
          {...register("subject")}
        />
      </div>
      <div className={AddNoticeStyle.form__group}>
        <label htmlFor="content" className={AddNoticeStyle.form__group__label}>
          Content
        </label>
        <textarea
          id="content"
          placeholder="Enter Notice Content"
          className={`${AddNoticeStyle.form__group__input} input`}
          {...register("content")}
          rows={5}
        />
      </div>
      <div className={AddNoticeStyle.form__submit}>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default Index;
