import moment from "moment";
import React from "react";

import { Notice } from "../../../Interfaces/ParkingArea";

import DeleteIcon from "../../../images/delete_dark_icon@2x.png";

import * as NoticeCardStyle from "./NoticeCard.module.scss";

interface Props {
  notice: Notice;
  onDelete: () => void;
}

const Index = ({ notice, onDelete }: Props) => {
  return (
    <div className={NoticeCardStyle.card}>
      <button onClick={onDelete} className={NoticeCardStyle.card__delete}>
        <img src={DeleteIcon} alt="delete" width={28} height={28} />
      </button>
      <span className={NoticeCardStyle.card__subject}>{notice.subject}</span>
      <span className={NoticeCardStyle.card__content}>{notice.content}</span>
      <span className={NoticeCardStyle.card__author}>
        By Self At {moment(notice.createdAt).format("DD/MM/YYYY hh:mm A")}
      </span>
    </div>
  );
};

export default Index;
