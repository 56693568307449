import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { ManagerQuery } from "../../Interfaces/User";

import { GetDomesticWorkers } from "../../Query/User";

import Server from "../../utils/Server";
import Logger from "../../utils/Logger";

import AddImage from "../../images/plus_icon2@2x.png";

import Loader from "../Loader";
import DomesticHelpCard from "./DomesticHelpCard";

import * as DomesticHelpStyle from "./DomesticHelp.module.scss";
import { navigate } from "gatsby";
import client from "../../gatsby-plugin-apollo/client";

interface Props {
  areaId: string;
  areaName: string;
  managerId?: string;
}

interface AddManager {
  parkingArea?: string;
  role: string[];
  contactNumber?: string;
  name?: string;
  email?: string;
  gender?: string;
  img?: File;
}

const Index = ({ areaId, areaName }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const {
    data: domesticWorkersData,
    loading,
    error,
    fetchMore,
    refetch,
  } = useQuery<ManagerQuery>(GetDomesticWorkers, {
    variables: {
      parkingArea: `/parking_areas/${areaId}`,
      role: "ROLE_DOMESTIC",
      status_list: ["ACTIVE", "INACTIVE"],
      order: [{ createdAt: "DESC" }],
    },
  });

  useEffect(() => {
    if (error) Logger.showError(error);
  }, [error]);

  useEffect(() => {
    refetch();
  }, []);

  const delManager = (managerId: string) => {
    const shortManagerId = managerId.split("/")[2];
    setIsLoading(true);
    Server.del(`/parking_manager/${shortManagerId}/remove`)
      .then(() => {
        client.cache.modify({
          fields: {
            parkingAreaManagers(exitstingManagerRef, { readField }) {
              const temp = exitstingManagerRef.collection?.filter(
                (managerRef) => managerId !== readField("id", managerRef)
              );
              return {
                collection: temp,
                paginationInfo: exitstingManagerRef?.paginationInfo,
              };
            },
          },
        });
      })
      .catch((err) => {
        Logger.showError(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={DomesticHelpStyle.container}>
        <div className={DomesticHelpStyle.header}>
          <span>{areaName} (Domestic Help)</span>
          <button
            onClick={() => navigate(`/addmanager/${areaId}/domestic`)}
            className="addbtn"
          >
            <span>Add Manager</span>
            <img src={AddImage} alt="" />
          </button>
        </div>
        <div className={DomesticHelpStyle.content}>
          {domesticWorkersData?.parkingAreaManagers.collection.map(
            (domesticHelp) => (
              <DomesticHelpCard
                isHelper
                manager={domesticHelp}
                onClickRemove={() => {
                  delManager(domesticHelp.id);
                }}
              />
            )
          )}
          {domesticWorkersData?.parkingAreaManagers &&
            !loading &&
            !domesticWorkersData.parkingAreaManagers.collection.length && (
              <span className="avgText">No Domestic Help</span>
            )}
        </div>
        {domesticWorkersData?.parkingAreaManagers &&
          domesticWorkersData?.parkingAreaManagers.paginationInfo.lastPage >
            page && (
            <div className="loadmore">
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const temp = Object.assign({}, prev, {
                        parkingAreaManagers: {
                          collection: [
                            ...prev.parkingAreaManagers.collection,
                            ...fetchMoreResult.parkingAreaManagers.collection,
                          ],
                          paginationInfo:
                            fetchMoreResult.parkingAreaManagers.paginationInfo,
                        },
                      });
                      setPage(page + 1);
                      return temp;
                    },
                  }).catch((err) => {
                    Logger.showError(err);
                  });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>
      <Loader isLoading={loading || isLoading} />
    </>
  );
};

export default Index;
