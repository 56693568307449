import { gql, useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import {
  CreateNoticeQuery,
  GetNoticesQuery,
  UpdateNoticeQuery,
} from "../../Query/ParkingArea";
import Logger from "../../utils/Logger";

import NoticeCard from "./NoticeCard";
import Loader from "../Loader";
import Modal from "../Modal";
import AddNoticeModal from "../Modals/AddNotice";

import AddImage from "../../images/plus_icon2@2x.png";
import {
  CreateNotice,
  Notice,
  NoticeBoardQuery,
  UpdateNotice,
} from "../../Interfaces/ParkingArea";

import * as NoticeBoardStyle from "./NoticeBoard.module.scss";

interface Props {
  areaId: string;
  areaName: string;
  managerId?: string;
}

const Index = ({ areaId, areaName }: Props) => {
  const [isAddNotice, setIsAddNotice] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: noticesData,
    loading,
    error,
    fetchMore,
    refetch,
  } = useQuery<NoticeBoardQuery>(GetNoticesQuery, {
    variables: {
      parkingArea: `/parking_areas/${areaId}`,
      order: [{ id: "DESC" }],
      status: "ACTIVE",
    },
  });

  const [createNotice, { loading: createingNotice, error: createNoticeError }] =
    useMutation<CreateNotice>(CreateNoticeQuery);

  const [updateNotice, { loading: updaingNotice, error: updateNoticeError }] =
    useMutation<UpdateNotice>(UpdateNoticeQuery);

  useEffect(() => {
    const err = error || createNoticeError || updateNoticeError;
    if (err) Logger.showError(err);
  }, [error, createNoticeError, updateNoticeError]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className={NoticeBoardStyle.container}>
        <div className={NoticeBoardStyle.header}>
          <span>{areaName}(Notice Board)</span>
          <button
            onClick={() => {
              setIsAddNotice(true);
            }}
            className="addbtn"
          >
            <span>Add Notice</span>
            <img src={AddImage} alt="" />
          </button>
        </div>
        {!noticesData?.noticeBoards.collection.length && !loading && (
          <div className="avgText">No Notices</div>
        )}
        <div className={NoticeBoardStyle.content}>
          {noticesData?.noticeBoards.collection?.map((notice) => (
            <NoticeCard
              key={notice.id}
              notice={notice}
              onDelete={() => {
                updateNotice({
                  variables: {
                    id: notice.id,
                    status: `/notice_board_statuses/2`,
                  },
                  update(cache, { data }) {
                    if (data) {
                      const update = data.updateNoticeBoard;
                      cache.modify({
                        fields: {
                          noticeBoards(existingNotices = [], { readField }) {
                            const temp = existingNotices?.collection?.filter(
                              (noticeRef) =>
                                readField("id", noticeRef) !==
                                update.noticeBoard.id
                            );

                            return {
                              collection: temp,
                              paginationInfo:
                                noticesData.noticeBoards.paginationInfo,
                            };
                          },
                        },
                      });
                    }
                  },
                }).catch((err) => {
                  Logger.showError(err);
                });
              }}
            />
          ))}
        </div>
        {noticesData?.noticeBoards &&
          noticesData.noticeBoards.paginationInfo.lastPage > page && (
            <div className="loadmore">
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const temp = Object.assign({}, prev, {
                        noticeBoards: {
                          collection: [
                            ...prev.noticeBoards.collection,
                            ...fetchMoreResult.noticeBoards.collection,
                          ],
                          paginationInfo:
                            fetchMoreResult.noticeBoards.paginationInfo,
                        },
                      });

                      setPage(page + 1);
                      return temp;
                    },
                  });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>
      <Modal
        isOpen={isAddNotice}
        title="Add Notice"
        onClose={() => setIsAddNotice(false)}
        showHeader
      >
        <AddNoticeModal
          onCreate={(data) => {
            setIsAddNotice(false);
            createNotice({
              variables: {
                subject: data.subject,
                content: data.content,
                parkingArea: `/parking_areas/${areaId}`,
              },
              update(cache, { data }) {
                console.log(data);

                cache.modify({
                  fields: {
                    noticeBoards(existingNotices = [], { readField }) {
                      const newNoticeRef = cache.writeFragment({
                        data: data?.createNoticeBoard.noticeBoard,
                        fragment: gql`
                          fragment createNoticeBoard on noticeBoards {
                            id
                            subject
                            content
                            createdAt
                            admin {
                              id
                              name
                              contactNumber
                            }
                          }
                        `,
                      });

                      const temp = [
                        newNoticeRef,
                        ...existingNotices?.collection,
                      ];

                      return {
                        collection: temp,
                        paginationInfo:
                          noticesData?.noticeBoards.paginationInfo,
                      };
                    },
                  },
                });
              },
            }).catch((err) => Logger.showError(err));
          }}
        />
      </Modal>
      <Loader
        isLoading={loading || createingNotice || updaingNotice || false}
      />
    </>
  );
};

export default Index;
