import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import AddImage from "../../images/plus_icon2@2x.png";
import {
  Gate,
  GateManager,
  GetGateManger,
  GetGates,
} from "../../Interfaces/ParkingArea";
import {
  DeleteGateManagerQuery,
  GetGatesQuery,
  GetManagerGates,
  CreateGateManagerQuery,
  CreateGateQury,
  UpdateGateQuery,
} from "../../Query/ParkingArea";
import Logger from "../../utils/Logger";

import GatesCard from "./GatesCard";
import Loader from "../Loader";

import * as GatesStyle from "./Gates.module.scss";

import { navigate } from "gatsby";
import client from "../../gatsby-plugin-apollo/client";

interface Props {
  areaId: string;
  areaName: string;
  managerId?: string;
}

interface GetManager {
  parkingAreaManagers: {
    collection: GateManager[];
  };
}

interface DeleteGateManager {
  deleteGateManager: {
    gateManager: GateManager;
  };
}
interface CreateGateManager {
  createGateManager: {
    gateManager: GateManager;
  };
}

interface CreateGate {
  createGate: {
    gate: Gate;
  };
}

const getManager = gql`
  query GetGateKeepers(
    $parkingArea: String
    $role: String
    $manager_name: String
    $manager_contactNumber: String
    $status_code: String
  ) {
    parkingAreaManagers(
      parkingArea: $parkingArea
      role: $role
      manager_name: $manager_name
      manager_contactNumber: $manager_contactNumber
      status_code: $status_code
    ) {
      collection {
        id
        manager {
          id
          name
        }
      }
    }
  }
`;

const Index = ({ areaId, areaName, managerId }: Props) => {
  const [gateName, setGateName] = useState<string>("");
  const { data: defaultManagers, error: defaultManagerError } =
    useQuery<GetManager>(getManager, {
      variables: {
        parkingArea: `/parking_areas/${areaId}`,
        role: "ROLE_MANAGER_GUARD",
        status_code: "ACTIVE",
      },
      fetchPolicy: "network-only",
    });

  const [getGates, { data, loading, error, refetch }] = useLazyQuery<GetGates>(
    GetGatesQuery,
    {
      variables: {
        parkingArea: `/parking_areas/${areaId}`,
      },
    }
  );

  const [
    getGateManager,
    {
      data: managerGates,
      loading: managerGateLoading,
      error: managerGateError,
    },
  ] = useLazyQuery<GetGateManger>(GetManagerGates, {
    variables: {
      parkingArea: `/parking_areas/${areaId}`,
      manager: `users/${managerId}`,
    },
    fetchPolicy: "network-only",
  });

  const [createGate, { loading: createGateLoading, error: createGateError }] =
    useMutation<CreateGate>(CreateGateQury);

  const [updateGate, { error: updateGateError, loading: updateGateLoading }] =
    useMutation(UpdateGateQuery);

  const [
    createGateManager,
    { loading: createGateManagerLoading, error: creteGateManagerError },
  ] = useMutation<CreateGateManager>(CreateGateManagerQuery);

  const [
    deleteGateManager,
    { loading: deleteGateManagerLoading, error: deleteAreaManagerError },
  ] = useMutation<DeleteGateManager>(DeleteGateManagerQuery);

  useEffect(() => {
    if (managerId) {
      getGateManager();
    } else {
      getGates();
    }
  }, []);

  useEffect(() => {
    const err =
      error ||
      managerGateError ||
      defaultManagerError ||
      creteGateManagerError ||
      createGateError ||
      deleteAreaManagerError ||
      updateGateError;
    if (err) Logger.showError(err);
  }, [
    managerGateError,
    defaultManagerError,
    error,
    creteGateManagerError,
    deleteAreaManagerError,
    createGateError,
    updateGateError,
  ]);

  return (
    <>
      <div className={GatesStyle.container}>
        <div className={GatesStyle.header}>
          <span>{areaName} (Gates)</span>

          {!managerId && (
            <div className={GatesStyle.add}>
              <input
                value={gateName}
                onChange={(e) => {
                  setGateName(e.target.value);
                }}
                type="text"
                placeholder="Enter Gate Name"
              />
              <button
                onClick={() => {
                  if (gateName !== "") {
                    createGate({
                      variables: {
                        name: gateName,
                        parkingArea: `/parking_areas/${areaId}`,
                      },
                    })
                      .then((res) => {
                        client.writeQuery({
                          query: GetGatesQuery,
                          variables: {
                            parkingArea: `/parking_areas/${areaId}`,
                          },
                          data: {
                            gates: {
                              collection: data?.gates?.collection
                                ? [
                                    ...data?.gates?.collection,
                                    res.data?.createGate?.gate,
                                  ]
                                : [res.data?.createGate.gate],
                              paginationInfo: data?.gates?.paginationInfo,
                            },
                          },
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .finally(() => setGateName(""));
                  } else {
                    Logger.showError(new Error("Enter Gate Name."));
                  }
                }}
                className={GatesStyle.add__btn}
              >
                <span className="text-nowrap">Add Gate</span>
                <img src={AddImage} alt="Add Gate" />
              </button>
            </div>
          )}
        </div>
        <div className={GatesStyle.content}>
          <div className={GatesStyle.list}>
            {managerId && !managerGates?.gateManagers?.collection.length && (
              <span className="avgText">No Gates Found.</span>
            )}
            {!managerId && !data?.gates?.collection.length && (
              <span className="avgText">No Gates Found.</span>
            )}

            {!managerId ? (
              <>
                {data?.gates?.collection.map((gate) => (
                  <GatesCard
                    key={gate.id}
                    areaId={areaId}
                    gate={gate}
                    defaultManager={
                      defaultManagers?.parkingAreaManagers?.collection
                    }
                    onManagerRemove={(managerId) => {
                      deleteGateManager({
                        variables: {
                          gateManager: managerId,
                        },
                      })
                        .then(() => {
                          client.writeQuery({
                            query: GetGatesQuery,
                            variables: {
                              parkingArea: `/parking_areas/${areaId}`,
                            },
                            data: {
                              gates: {
                                collection: data.gates?.collection.map((g) =>
                                  g.id === gate.id
                                    ? {
                                        ...g,
                                        gateManagers: {
                                          collection:
                                            g.gateManagers?.collection.filter(
                                              ({ id }) => id !== managerId
                                            ),
                                        },
                                      }
                                    : g
                                ),
                                paginationInfo: data?.gates?.paginationInfo,
                              },
                            },
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                    onManagerAdd={(managerId) => {
                      createGateManager({
                        variables: {
                          manager: managerId,
                          gate: gate.id,
                        },
                      })
                        .then((res) => {
                          client.writeQuery({
                            query: GetGatesQuery,
                            variables: {
                              parkingArea: `/parking_areas/${areaId}`,
                            },
                            data: {
                              gates: {
                                collection: data.gates?.collection.map((g) =>
                                  g.id === gate.id
                                    ? {
                                        ...g,
                                        gateManagers: {
                                          collection: [
                                            ...g.gateManagers?.collection,
                                            res.data?.createGateManager
                                              .gateManager,
                                          ],
                                        },
                                      }
                                    : g
                                ),
                                paginationInfo: data?.gates?.paginationInfo,
                              },
                            },
                          });
                          // refetch && refetch();
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                    onClickVisitor={() => {
                      navigate(
                        `/society/visitor/${areaId}/${encodeURIComponent(
                          areaName
                        )}/${gate.id.split("/")[2]}/owner`
                      );
                    }}
                    onRemoveGate={() => {
                      updateGate({
                        variables: {
                          id: gate.id,
                          status: "/gate_statuses/2",
                        },
                      })
                        .then(() => {
                          client.writeQuery({
                            query: GetGatesQuery,
                            variables: {
                              parkingArea: `/parking_areas/${areaId}`,
                            },
                            data: {
                              gates: {
                                collection: data.gates.collection.filter(
                                  ({ id }) => id !== gate.id
                                ),
                                paginationInfo: data?.gates.paginationInfo,
                              },
                            },
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  />
                ))}
              </>
            ) : (
              <>
                {managerGates?.gateManagers?.collection.map(
                  ({ manager, gate }) => (
                    <GatesCard
                      gate={gate}
                      areaId={areaId}
                      onClickVisitor={() => {
                        navigate(
                          `/society/visitor/${areaId}/${encodeURIComponent(
                            areaName
                          )}/${gate.id.split("/")[2]}/owner`
                        );
                      }}
                    />
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Loader
        isLoading={
          loading ||
          managerGateLoading ||
          createGateManagerLoading ||
          deleteGateManagerLoading ||
          createGateLoading ||
          updateGateLoading ||
          false
        }
      />
    </>
  );
};

export default Index;
