import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { Downgraded, useState as useHookState } from "@hookstate/core";

import Loader from "../Loader";
import ComplaintCard from "./HelpDeskCard";

import {
  GetComplaints,
  UpdateResidanceComplaint,
} from "../../Interfaces/Complaint";

import {
  GetComplaintsQuery,
  UpdateResidentComplaint,
} from "../../Query/Complaints";

import AddImage from "../../images/plus_icon2@2x.png";

import * as HelpDeskStyle from "./HelpDesk.module.scss";
import Logger from "../../utils/Logger";
import AppState from "../../States/AppState";

interface Props {
  areaId: string;
  areaName: string;
  managerId?: string;
}

const Index = ({ areaId, areaName, managerId }: Props) => {
  const [page, setPage] = useState(1);

  const roles = useHookState(AppState).user.attach(Downgraded).get()?.roles;

  const { data, loading, error, fetchMore, refetch } = useQuery<GetComplaints>(
    GetComplaintsQuery,
    {
      variables: {
        parkingArea: `/parking_areas/${areaId}`,
        order: [{ createdAt: "DESC" }],
        manager: managerId ? `/users/${managerId}` : undefined,
        status_list: [
          "COMPLAINED",
          "RECOMPLAINED",
          "ASSIGNED",
          "UNASSIGNED",
          "REASSIGNED",
          "OPENED",
          "INPROCESS",
          "CLOSED",
        ],
      },
    }
  );

  const [
    updateComplaint,
    { error: updateStatusError, loading: updateStatusLoading },
  ] = useMutation<UpdateResidanceComplaint>(UpdateResidentComplaint);

  useEffect(() => {
    const err = updateStatusError || error;
    if (
      err &&
      err.message !== 'Variable "$id" of required type "ID!" was not provided.'
    ) {
      Logger.showError(err);
    }
  }, [error, updateStatusError]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className={HelpDeskStyle.container}>
        <div className={HelpDeskStyle.header}>
          <span>{areaName}(Help Desk)</span>
          {!roles?.includes("ROLE_GATEKEEPER") &&
            !roles?.includes("ROLE_HELPER") && (
              <button
                onClick={() => {
                  navigate(`/add/complaint/${areaId}`);
                }}
                className="addbtn"
              >
                <span>Add Complaint</span>
                <img src={AddImage} alt="" />
              </button>
            )}
        </div>
        {!loading && !data?.residenceComplaints?.collection.length && (
          <div className="avgText">No Complaint</div>
        )}
        <div className={HelpDeskStyle.content}>
          {data?.residenceComplaints?.collection.map((complaint) => (
            <ComplaintCard
              key={complaint.id}
              complaint={complaint}
              onRemove={() => {
                updateComplaint({
                  variables: {
                    id: complaint.id,
                    status: "/residence_complaint_statuses/9",
                    comment: "Complaint Deleted",
                  },
                  update(cache, { data }) {
                    if (data) {
                      const updateComplaint = data.updateResidenceComplaint;
                      cache.modify({
                        fields: {
                          residenceComplaints(existingComplaint = []) {
                            console.log(existingComplaint);
                            return existingComplaint?.collection?.filter(
                              (complaint) =>
                                complaint.id !==
                                updateComplaint.residenceComplaint.id
                            );
                          },
                        },
                      });
                    }
                  },
                }).catch((err) => {
                  console.log(err);
                });
              }}
              canRemove={complaint.user.id === AppState.user.get()?.id}
            />
          ))}
        </div>
        {data?.residenceComplaints &&
          data?.residenceComplaints?.paginationInfo.lastPage > page && (
            <div className="loadmore">
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const temp = Object.assign({}, prev, {
                        residenceComplaints: {
                          paginationInfo:
                            fetchMoreResult.residenceComplaints?.paginationInfo,
                          collection: [
                            ...prev.residenceComplaints?.collection,
                            ...fetchMoreResult.residenceComplaints?.collection,
                          ],
                        },
                      });
                      setPage(page + 1);

                      return temp;
                    },
                  }).catch((err) => {
                    Logger.showError(err);
                  });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>
      <Loader isLoading={loading || updateStatusLoading || false} />
    </>
  );
};

export default Index;
