import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import { useState as useHookState } from "@hookstate/core";

import { Gate, GateManager } from "../../../Interfaces/ParkingArea";

import DeleteIcon from "../../../images/delete_dark_icon@2x.png";

import * as GatesCardStyle from "./GatesCard.module.scss";

import { gql } from "@apollo/client";
import Logger from "../../../utils/Logger";
import AreaRoles from "../../../States/AreaRoles";
import client from "../../../gatsby-plugin-apollo/client";

interface Props {
  gate: Gate;
  areaId: string;
  defaultManager?: GateManager[];
  onManagerRemove?: (managerId: string) => void;
  onManagerAdd?: (managerId: string) => void;
  onClickVisitor: () => void;
  onRemoveGate?: () => void;
}

interface GetManager {
  parkingAreaManagers: {
    collection: GateManager[];
  };
}

const getManager = gql`
  query GetGateKeepers(
    $parkingArea: String
    $role: String
    $manager_name: String
    $manager_contactNumber: String
    $status_code: String
  ) {
    parkingAreaManagers(
      parkingArea: $parkingArea
      role: $role
      manager_name: $manager_name
      manager_contactNumber: $manager_contactNumber
      status_code: $status_code
    ) {
      collection {
        id
        manager {
          id
          name
        }
      }
    }
  }
`;

const Index = ({
  gate,
  defaultManager,
  areaId,
  onManagerRemove,
  onManagerAdd,
  onClickVisitor,
  onRemoveGate,
}: Props) => {
  const [selectedManager, setSelectedManager] =
    useState<{ label?: string; value?: string }>();

  const areaRolesState = useHookState(AreaRoles).get();

  const [myRole, setMyRole] = useState<"owner" | "guard">();

  useEffect(() => {
    const currentArea = areaRolesState.areas.filter(
      ({ areaId: id }) => id.split("/")[2] === areaId
    )[0];
    if (currentArea) {
      if (
        currentArea.role.length === 1 &&
        currentArea.role.includes("ROLE_PARTNER")
      ) {
        setMyRole("owner");
      }
      if (currentArea.role.includes("ROLE_GATEKEEPER")) {
        setMyRole("guard");
      }
    }
  }, [areaRolesState]);

  const fetchGateKeepers = (
    searchStr: string
  ): Promise<{ label: string; value: string }[]> => {
    return new Promise((resolve, reject) => {
      client
        .query<GetManager>({
          query: getManager,
          variables: {
            parkingArea: `/parking_areas/${areaId}`,
            role: "ROLE_MANAGER_GUARD",
            status_code: "ACTIVE",
            manager_name: searchStr,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          resolve(
            res?.data?.parkingAreaManagers?.collection.map(
              ({ id, manager }) => {
                return {
                  value: manager.id || "",
                  label: manager.name || "",
                };
              }
            )
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return (
    <div className={GatesCardStyle.card}>
      <div className={GatesCardStyle.card__header}>
        <span>{gate.name}</span>
        {onRemoveGate && (
          <button
            onClick={() => {
              Swal.fire({
                type: "warning",
                title: "Warning",
                text: "Are You Sure",
                showCancelButton: true,
              }).then(({ value }) => {
                if (value) {
                  onRemoveGate();
                }
              });
            }}
          >
            <img src={DeleteIcon} alt="remove gate" width={32} />
          </button>
        )}
      </div>
      <div className={GatesCardStyle.card__content}>
        <div className={GatesCardStyle.card__content__head}>
          {myRole === "owner" && <span>Managers:-</span>}
          <button
            className={GatesCardStyle.visitorsbtn}
            onClick={onClickVisitor}
            {...{
              style: {
                width: `${myRole === "guard" ? "100%" : "unset"}`,
              },
            }}
          >
            Go To Visitors
          </button>
        </div>
        {myRole === "owner" && (
          <div className={GatesCardStyle.addmanager}>
            <AsyncSelect
              value={selectedManager}
              defaultOptions={defaultManager?.map(
                ({ manager: { name, id } }) => {
                  return {
                    value: id,
                    label: name,
                  };
                }
              )}
              loadOptions={fetchGateKeepers}
              onChange={(e) => {
                if (e) setSelectedManager(e);
                console.log(e);
              }}
              isClearable
            />
            {onManagerAdd && (
              <button
                onClick={() => {
                  if (selectedManager?.value) {
                    onManagerAdd(selectedManager?.value);
                    setSelectedManager(undefined);
                  } else
                    Logger.showError(new Error("Select Manager To Continue"));
                }}
              >
                Add Manger
              </button>
            )}
          </div>
        )}
        {myRole === "owner" && (
          <div className={GatesCardStyle.managerList}>
            {gate.gateManagers?.collection.map(
              ({ id, manager: { name, contactNumber } }) => (
                <div className={GatesCardStyle.managerList__item} key={id}>
                  <div>
                    <span>{name}</span>
                    <span>{contactNumber}</span>
                  </div>
                  {onManagerRemove && (
                    <button
                      onClick={() => {
                        Swal.fire({
                          type: "warning",
                          title: "Warning",
                          text: "Are You Sure",
                          showCancelButton: true,
                        }).then(({ value }) => {
                          if (value) {
                            onManagerRemove(id);
                          }
                        });
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt="remove"
                        width={28}
                        height={28}
                      />
                    </button>
                  )}
                </div>
              )
            )}
            {!gate.gateManagers?.collection?.length && (
              <span className="align-self-center">No Gatekeeper Assigned.</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
