// extracted by mini-css-extract-plugin
export var card = "VisitorCard-module--card--rwZH1";
export var card__header = "VisitorCard-module--card__header--WjqZw";
export var card__content = "VisitorCard-module--card__content--RDuv1";
export var card__content__details = "VisitorCard-module--card__content__details--B-8fv";
export var name = "VisitorCard-module--name--dZt6L";
export var svg = "VisitorCard-module--svg--sgOtd";
export var healths = "VisitorCard-module--healths--sm1RC";
export var footer = "VisitorCard-module--footer--V8355";
export var btn = "VisitorCard-module--btn--qKlLZ";
export var btn__two = "VisitorCard-module--btn__two--9JiKo";
export var btn__approve = "VisitorCard-module--btn__approve--2gRfy";
export var out = "VisitorCard-module--out--oKHLl";
export var select = "VisitorCard-module--select--YJr5p";