import React from "react";

import * as ToggleButtonStyle from "./ToggleButton.module.scss";

interface Props {
  toggleSelected: () => void;
  selected: boolean;
  color?: "Primary" | "Secondary" | "Tertiary" | "Danger";
  isDisable?: boolean;
}

const Index = ({ selected, toggleSelected, color, isDisable }: Props) => {
  return (
    <div
      className={`${ToggleButtonStyle.toggleContainer}`}
      onClick={() => {
        if (!isDisable) toggleSelected();
      }}
    >
      <div
        className={`${ToggleButtonStyle.dialog_btn} ${
          selected ? "" : ToggleButtonStyle.disable
        } ${color === "Tertiary" && ToggleButtonStyle.bg__tertiary}`}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default Index;
