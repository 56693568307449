import React, { ReactElement } from "react";

import * as ModalStyle from "./Modal.module.scss";

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  showHeader?: boolean;
  children: ReactElement | ReactElement[];
}

const Index = ({ isOpen, children, showHeader, onClose, title }: Props) => {
  return (
    <>
      <div
        className={
          isOpen ? ModalStyle.modal__active : ModalStyle.modal__inactive
        }
      >
        {showHeader && (
          <div className={`${ModalStyle.modal__header} grediant1`}>
            <h5>{title}</h5>
          </div>
        )}
        <div className={ModalStyle.modal__content}>{children}</div>
      </div>
      <div
        onClick={onClose}
        className={
          isOpen ? ModalStyle.modal__overlay : ModalStyle.modal__overlay__hidden
        }
      />
    </>
  );
};

export default Index;
