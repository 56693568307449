import React from "react";

import Swal from "sweetalert2";

import { Tower } from "../../../Interfaces/ParkingArea";

import DeleteIcon from "../../../images/delete_dark_icon@2x.png";
import DefaultImg from "../../../images/profile-pic-icon.png";

import * as ResidenceCardStyle from "./ResidenceCard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { User } from "../../../Interfaces/User";

interface Props {
  residence: Tower;
  onDelete: (userId: string) => void;
  onView: () => void;
}

const Index = ({ residence, onDelete, onView }: Props) => {
  return (
    <div className={ResidenceCardStyle.card} onClick={onView}>
      <div className={ResidenceCardStyle.card__header}>
        <span>Flat No: {residence?.flatName}</span>
        {/* <button>
          <img src={DeleteIcon} alt="delete" width={28} height={28} />
        </button> */}
      </div>
      <div className={ResidenceCardStyle.card__content}>
        <img
          src={
            residence.userResidences.collection?.filter(
              ({ type }) => type.code === "RO"
            )[0]?.user.userMedias?.collection[0]?.mediaUrl?.url || DefaultImg
          }
          width={72}
          height={72}
          alt=""
          {...{ style: { borderRadius: "100px" } }}
        />
        <div className={ResidenceCardStyle.owner}>
          <span>Owner</span>
          <span>
            {residence.userResidences.collection?.filter(
              ({ type }) => type.code
            )[0]?.user.name || "Not Assigned"}
          </span>
          <span>
            {
              residence.userResidences.collection?.filter(
                ({ type }) => type.code
              )[0]?.user.contactNumber
            }
          </span>
        </div>
        <div>
          <FontAwesomeIcon icon={faAngleRight} size="2x" />

          {/* {residence.userResidences.collection?.filter(
            ({ type }) => type.code === "RO"
          ).length ? (
            <button
              className={ResidenceCardStyle.card__action__btn__delete}
              title="Remove Member"
              onClick={() => {
                if (residence.userResidences.collection)
                  Swal.fire({
                    type: "info",
                    title: "Warning",
                    text: "You want to remove this residence",
                    showCancelButton: true,
                  }).then((res) => {
                    if (res.value && residence.userResidences.collection) {
                      onDelete(
                        residence.userResidences.collection.filter(
                          ({ type }) => type.code === "RO"
                        )[0]?.id || ""
                      );
                    }
                  });
              }}
            >
              <img src={DeleteIcon} alt=" delete" width={28} height={28} />
            </button>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Index;
