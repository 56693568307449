import React, { useState } from "react";
import { VisitorLog } from "../../../Interfaces/User";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faHeadSideMask,
  faSignInAlt,
  faSyringe,
  faTemperatureLow,
} from "@fortawesome/free-solid-svg-icons";

import DefaultImg from "../../../images/profile-pic-icon.png";

import { Gate } from "../../../Interfaces/ParkingArea";

import * as VisitorCardStyle from "./VisitorCard.module.scss";

interface Props {
  visitorLog: VisitorLog;
  gates?: Gate[];
  onApprove: () => void;
  onCheckout: (gate?: string) => void;
}

const Index = ({
  visitorLog: { residenceVisitor: visitor, visitorPhoto },
  gates,
  onApprove,
  onCheckout,
}: Props) => {
  const [isCheckout, setIsCheckout] =
    useState<{ isOpen?: boolean; gate?: string }>();

  const getHealthByType = (type: "TEMP" | "MASK" | "VACCINATED") => {
    const health = visitor?.residenceVisitorHealths?.collection.filter(
      ({ attributeType }) => attributeType.code === type
    );

    if (health.length) {
      return health[0]?.attributeValue;
    } else return "N/A";
  };
  return (
    <div className={VisitorCardStyle.card}>
      <div className={VisitorCardStyle.card__header}>
        <div>
          {visitor.visitingPurpose.code === "HELP"
            ? "Domestic Help"
            : `${visitor.residence?.towerName}-${visitor.residence?.flatName}`}
        </div>
      </div>
      <div className={VisitorCardStyle.card__content}>
        <img
          src={
            visitor?.residenceVisitorMedia?.collection[0]?.mediaUrl?.url ||
            visitorPhoto?.url ||
            DefaultImg
          }
          alt=""
          width={84}
          height={84}
          {...{ style: { borderRadius: "100px", objectFit: "cover" } }}
        />
        <div className={VisitorCardStyle.card__content__details}>
          <span className={VisitorCardStyle.name}>{visitor.vistorName}</span>
          <span>
            {visitor.visitingPurpose.code} - {visitor.visitorContactNumber}
          </span>
          <span title="In Time">
            <FontAwesomeIcon color="blue" icon={faSignInAlt} />{" "}
            {moment(
              visitor.residenceVistorLogs.collection.filter(
                ({ entryType: { code } }) => code === "IN"
              )[0]?.createdAt
            ).format("DD/MM/YY - hh:mm A")}{" "}
            {
              visitor.residenceVistorLogs.collection.filter(
                ({ entryType: { code } }) => code === "IN"
              )[0]?.gate?.name
            }
          </span>
          {visitor.residenceVistorLogs.collection.filter(
            ({ entryType }) => entryType.code === "OUT"
          ).length ? (
            <span title="Out Time">
              <FontAwesomeIcon
                className={VisitorCardStyle.svg}
                color="blue"
                icon={faSignInAlt}
              />{" "}
              {moment(
                visitor.residenceVistorLogs.collection.filter(
                  ({ entryType }) => entryType.code === "OUT"
                )[0]?.createdAt
              ).format("DD/MM/YY - hh:mm A")}{" "}
              <span>
                {
                  visitor.residenceVistorLogs.collection.filter(
                    ({ entryType: { code } }) => code === "OUT"
                  )[0]?.gate?.name
                }
              </span>
            </span>
          ) : (
            ""
          )}
          {!visitor.residenceVistorLogs.collection.filter(
            ({ entryType }) => entryType.code === "OUT"
          ).length &&
            ["APPROVED"].includes(visitor.status.code) && <span>Stll In</span>}

          {visitor.status.code === "LEAVE_AT_DOOR" && (
            <span>Leave At Gate</span>
          )}
          {!visitor.outTime && visitor.status.code === "REQUESTED" && (
            <span>Waiting For Approval</span>
          )}
          {!visitor.outTime && visitor.status.code === "REJECTED" && (
            <span>Rejected</span>
          )}
          <div className={VisitorCardStyle.healths}>
            <span>
              <FontAwesomeIcon icon={faHeadSideMask} />{" "}
              {getHealthByType("MASK")}
            </span>
            <span>
              <FontAwesomeIcon icon={faTemperatureLow} />{" "}
              {getHealthByType("TEMP")}
            </span>
            <span>
              <FontAwesomeIcon icon={faSyringe} />{" "}
              {getHealthByType("VACCINATED")}
            </span>
          </div>
        </div>
      </div>
      <div className={VisitorCardStyle.footer}>
        {visitor.status.code === "REQUESTED" && (
          <button
            className={`${VisitorCardStyle.btn} ${VisitorCardStyle.btn__approve}`}
            onClick={onApprove}
          >
            Approve
          </button>
        )}
        {visitor.status.code === "APPROVED" && (
          <span
            className={`${VisitorCardStyle.btn} ${VisitorCardStyle.btn__two}`}
          >
            Approved
          </span>
        )}
        {visitor.status.code === "APPROVED" &&
          !isCheckout?.isOpen &&
          !visitor.residenceVistorLogs.collection.filter(
            ({ entryType }) => entryType.code === "OUT"
          ).length && (
            <button
              className={`${VisitorCardStyle.btn} ${VisitorCardStyle.btn__approve}`}
              onClick={() => {
                if (!gates?.length) {
                  onCheckout();
                }
                if (gates?.length === 1) {
                  onCheckout(gates[0].id);
                }
                if (gates && gates?.length > 1) {
                  setIsCheckout({ isOpen: true });
                }
              }}
            >
              Check Out
            </button>
          )}
        {isCheckout?.isOpen &&
          !visitor.residenceVistorLogs.collection.filter(
            ({ entryType }) => entryType.code === "OUT"
          ).length && (
            <div className={VisitorCardStyle.out}>
              <select
                onChange={(e) => {
                  setIsCheckout({ ...isCheckout, gate: e.target.value });
                }}
                className={`${VisitorCardStyle.select}  input`}
              >
                <option value="">Select Exit Gate</option>
                {gates?.map(({ id, name }) => (
                  <option value={id}>{name}</option>
                ))}
              </select>
              <button
                onClick={() => {
                  if (isCheckout?.gate) onCheckout(isCheckout.gate);
                }}
              >
                Save
              </button>
            </div>
          )}

        {visitor.status.code === "LEAVE_AT_DOOR" && (
          <span className={`${VisitorCardStyle.btn}`}>Leave At Door</span>
        )}
        {visitor.status.code === "REJECTED" && (
          <span className={`${VisitorCardStyle.btn}`}>Rejected</span>
        )}
      </div>
    </div>
  );
};

export default Index;
