import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import ManagerCard from "../DomesticHelp/DomesticHelpCard";
import Loader from "../Loader";

import AddImage from "../../images/plus_icon2@2x.png";

import * as ManagersStyles from "./Managers.module.scss";
import { useQuery } from "@apollo/client";
import { Manager } from "../../Interfaces/User";
import { Pagination } from "../../Interfaces/Pagination";
import { GetDomesticWorkers } from "../../Query/User";
import Logger from "../../utils/Logger";
import Server from "../../utils/Server";
import client from "../../gatsby-plugin-apollo/client";

interface Props {
  areaId: string;
  areaName: string;
  managerId?: string;
}

interface ManagersQuery {
  parkingAreaManagers: {
    collection: Manager[];
    paginationInfo: Pagination;
  };
}

const Index = ({ areaId, areaName }: Props) => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: managersData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery<ManagersQuery>(GetDomesticWorkers, {
    variables: {
      parkingArea: `/parking_areas/${areaId}`,
      role: "ROLE_MANAGER",
      status_list: ["ACTIVE", "INACTIVE"],
      order: [{ createdAt: "DESC" }],
    },
  });

  const delManager = (managerId: string) => {
    const shortManagerId = managerId.split("/")[2];
    setIsLoading(true);
    Server.del(`/parking_manager/${shortManagerId}/remove`)
      .then(() => {
        client.cache.modify({
          fields: {
            parkingAreaManagers(exitstingManagerRef, { readField }) {
              const temp = exitstingManagerRef.collection?.filter(
                (managerRef) => managerId !== readField("id", managerRef)
              );
              return {
                collection: temp,
                paginationInfo: exitstingManagerRef?.paginationInfo,
              };
            },
          },
        });
      })
      .catch((err) => {
        Logger.showError(err);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) Logger.showError(error);
  }, [error]);

  return (
    <>
      <div className={ManagersStyles.container}>
        <div className={ManagersStyles.header}>
          <span>{areaName}(Managers)</span>
          <button
            onClick={() => navigate(`/addmanager/${areaId}/partner`)}
            className="addbtn"
          >
            <span>Add Manager</span>
            <img src={AddImage} alt="" />
          </button>
        </div>
        {!managersData?.parkingAreaManagers.collection.length && !loading && (
          <div className="avgText">No Managers</div>
        )}
        <div className={ManagersStyles.content}>
          {managersData?.parkingAreaManagers?.collection?.map((manager) => (
            <ManagerCard
              key={manager.id}
              manager={manager}
              onClickRemove={() => {
                delManager(manager.id);
              }}
            />
          ))}
        </div>
        {managersData?.parkingAreaManagers &&
          managersData?.parkingAreaManagers.paginationInfo.lastPage > page && (
            <div className="loadmore">
              <button
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: page + 1,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      const temp = Object.assign({}, prev, {
                        parkingAreaManagers: {
                          collection: [
                            ...prev.parkingAreaManagers.collection,
                            ...fetchMoreResult.parkingAreaManagers.collection,
                          ],
                          paginationInfo:
                            fetchMoreResult.parkingAreaManagers.paginationInfo,
                        },
                      });
                      setPage(page + 1);
                      return temp;
                    },
                  }).catch((err) => {
                    Logger.showError(err);
                  });
                }}
              >
                Load More
              </button>
            </div>
          )}
      </div>
      <Loader isLoading={loading || isLoading} />
    </>
  );
};

export default Index;
